import React from 'react';

import { ButtonWrapper, IconWrapper } from './button.style';

export default function Button({
    style, htmlType, title, disabled, className, isLoading, onClick, icon, number
}) {
    return (
        <ButtonWrapper
            style={style}
            htmlType={htmlType}
            onClick={disabled ? null : onClick}
            className={className ? `ButtonWrapper ${className} ${disabled || isLoading ? 'disabled' : ''}` : 'ButtonWrapper'}
        >
            {title}
            {icon && <IconWrapper>{icon}</IconWrapper>}
        </ButtonWrapper>
    );
}
