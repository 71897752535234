import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.5384616;
  border-radius: 3px;
  padding-top:9px;
  display:flex;
  align-intems:center;
  justify-content:center;
  &.primary {
    background-color:#EC407A;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    border-width: 0;
    height:35px;
  }
  &.disabled {
    border: 1px solid #dddddd;
    background: #F0F0F0;
    color: #B9BABE;
    &:hover {
      background: #dddddd;
      border: 1px solid #dddddd;
      cursor: no-drop;
    }

    &:active {
      background: #ddd;
      border: 1px solid #ddd;
      transform: scale(1);
    }
  }
`;

export const IconWrapper = styled.div`
  margin-left:8px
`;
