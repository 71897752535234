import React from "react";
import logo from "../../assets/images/logo_light.png";
import {
  Button,
  ThemeProvider,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

function emptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
}

const Header = ({ NavBarSwitch }) => {
  const theme = createTheme({
    palette: {
      neutral: {
        main: "#fff",
      },
      gray: {
        main: "#37474f",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="gray">
          <Toolbar>
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              News
          </Typography> */}
            <IconButton
              size="large"
              edge="start"
              color="neutral"
              aria-label="menu"
              onClick={() => {
                NavBarSwitch();
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img
                src={logo}
                alt=""
                style={{ marginRight: 30, marginTop: 5 }}
              />
            </Typography>

            <Stack direction="row" justifyContent="end">
              <Button
                onClick={() => {
                  emptyCache();
                  process.env.NODE_ENV === "development"
                    ? (window.location.href = "/")
                    : (window.location.href = "/offline_quiz");

                  
                }}
                color="neutral"
              >
                {"Logout"}
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};

export default Header;
