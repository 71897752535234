import React, { useState,useEffect } from "react";
import axios from "axios";
import { ReactComponent as ReadingIcon } from "../../assets/icons/reading.svg";
import { API_URL } from "../../constants/index";
import { IoIosArrowDropright } from "react-icons/io";
import {
  HeaderContent,
  LoginPageWrapper,
  HeaderTitle,
  HeaderSubtitle,
  LoginContent,
  LoginContainer,
  IconContainer,
  FormContainer,
} from "./login.style";
import { Box } from "@mui/material";
import Button from "../../componets/button/button.component";
import CookiePopup from "../../componets/cookie-popup/cookie-popup.component";
import { getQuestions } from "../dashboard/dashboard.page";


const indexedDB =
  window.indexedDB ||
  window.webkitIndexedDB ||
  window.mozIndexedDB ||
  window.OIndexedDB ||
  window.msIndexedDB,
  IDBTransaction =
    window.IDBTransaction ||
    window.webkitIDBTransaction ||
    window.OIDBTransaction ||
    window.msIDBTransaction;
let dbVersion = 2;

let request = indexedDB.open("recordings", dbVersion),
  db = null,
  createObjectStore = (dataBase) => {
    // Create an objectStore

    dataBase.createObjectStore("sounds");
  };

request.onupgradeneeded = function (event) {

  createObjectStore(event.target.result);
};

request.onerror = function (event) {
  console.log('You dont have access to indexedDb');
};

request.onsuccess = function (event) {

  db = request.result;
  console.log("db", db)
  db.onerror = function (event) {

  };
};

var da = {};
if (localStorage.getItem("unsentSounds") == null) {
  localStorage.setItem("unsentSounds", JSON.stringify(da));
}

if (localStorage.getItem("timers") == null) {
  let notImportant = [];
  localStorage.setItem("timers", JSON.stringify(notImportant));
}

const LoginPage = ({ login }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [questionnaires, setQuestionnaires] = useState(null);
  const [title, setTitle] = useState("Log in")
  const [loginMessage, setLoginMessage] = useState()

  useEffect(() => {
    if(questionnaires != null) {
      let waitingTime = 3500;
      if(questionnaires.length > 10) {
        waitingTime = 7000;
      }
      setTimeout(() => {
        if (process.env.NODE_ENV === "development") {
          window.location.href = "/projects";
        } else {
          window.location.href = "/offline_quiz/projects";
        }
      }, waitingTime);
    }
  }, [questionnaires]);

  var passwordHash = require("password-hash");
  if (localStorage.getItem("session") == null) {
    localStorage.setItem("session", 1);
  }
  if (localStorage.getItem("currentSession") == null) {
    localStorage.setItem("currentSession", 1);
  }
  if (localStorage.getItem("accounts") == null) {
    localStorage.setItem("accounts", 0);
  }
  const [accounts, setAccounts] = useState(localStorage.getItem("accounts"));
  const getUserToken = () => {
    return axios({
      method: "get",
      url: `${API_URL}/login`,
      withCredentials: true,
    })
      .then((response) => {
        
        return response.data.token;
      })
      .catch((err) => {
        console.log(err);
        // document.getElementById("creds").innerHTML = err.message + "(1)";
        let storedPasswords = [];
        let storedUsernames = [];
        for (let i = 0; i <= parseInt(accounts); ++i) {
          storedPasswords.push(localStorage.getItem("password" + i));
          storedUsernames.push(localStorage.getItem("username" + i));
        }
        for (let i = 0; i <= parseInt(accounts); ++i) {
          if (
            passwordHash.verify(password, storedPasswords[i]) &&
            storedUsernames[i] === username
          ) {
            localStorage.setItem("currentUser", username);
            if (process.env.NODE_ENV === "development") {
              window.location.href = "/dashboard";
            } else {
              window.location.href = "/offline_quiz/dashboard";
            }
          }
        }
      });
  };
  const loginUser = async () => {
    
    const token = await getUserToken();
    if (token) {
      const data = { username: username, password: password, token: token };
      
      axios({
        withCredentials: true,
        method: "post",
        url: `${API_URL}/login`,
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          
          if (response.data.status === "success") {
            let hashedPassword = passwordHash.generate(password);
          
            let found = false;
            for (let i = 0; i <= parseInt(accounts); ++i) {
              if (localStorage.getItem("username" + i) == username) {
                found = true;
              }
            }
            if (found == false) {
              localStorage.setItem("username" + accounts, username);
              localStorage.setItem("password" + accounts, hashedPassword);
              localStorage.setItem("accounts", parseInt(accounts) + 1);
              setAccounts(localStorage.getItem("accounts"));
            }
            localStorage.setItem("currentUser", username);
            getQuestions().then(setTitle("Logging in...."))
            setInterval(() => {
              setQuestionnaires(JSON.parse(localStorage.getItem("questionnaires")));
            }, 3000);
          }
        })
        .catch((error) => {
          // document.getElementById("creds").innerHTML = error.message + "(2)";
          console.log(error);
        });
    }
  };
  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        loginUser()
      }
    }

    
  return (
    
    <LoginPageWrapper className="LoginPageWrapper">
      <div onKeyDown={handleKeyDown}>
      {/* <div>{process.env.NODE_ENV}</div> */}
      <LoginContainer className="LoginContainer">
        <LoginContent className="LoginContent">
          <HeaderContent className="HeaderContent">
            <IconContainer className="IconContainer">
              <ReadingIcon style={{ height: "32px", width: "32.8px" }} />
            </IconContainer>
            <HeaderTitle className="HeaderTitle">
              {" "}
              Login to your account
            </HeaderTitle>
            <HeaderSubtitle className="HeaderSubtitle" id="creds">
              Enter your credentials below
            </HeaderSubtitle>
          </HeaderContent>
          <FormContainer className="FormContainer">
            <input
            
            style={{
              borderColor: 'transparent',
              borderBottomColor: "#ddd",
              outline: 'none',
              paddingBottom: 8,
              paddingTop: 8,
              display: 'block',
              fontSize: '13px',
          }}
              typeIcon="user"
              text={username}
              placeholder="Username"
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
            <input
            style={{
              marginTop: "30px",
              borderColor: 'transparent',
              borderBottomColor: "#ddd",
              outline: 'none',
              paddingBottom: 8,
              paddingTop: 8,
              display: 'block',
              fontSize: '13px',
          }}
              type="password"
              placeholder="Password"
              text={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />
            <Button
              style={{marginTop:"30px"}}
              title={title}
              className="primary"
              onClick={loginUser}
              icon={<IoIosArrowDropright style={{ width: 20, height: 20 }} />}
            />
          </FormContainer>
          <Box>{loginMessage}</Box>
        </LoginContent>
      </LoginContainer>
      <CookiePopup />
      </div>
    </LoginPageWrapper>
    
  );
};

export default LoginPage;
