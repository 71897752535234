import React from "react";
import CookieConsent from "react-cookie-consent";
function CookiePopup() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Am inteles"
      cookieName="CookieLogin"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "12px" }}
      expires={1}
    >
      Acest website foloseste cookie pentru a putea functiona bine. Va rugam sa
      da-ti enable la cookie pentru a functiona cum trebuie si opriti
      adblock-ul.
    </CookieConsent>
  );
}

export default CookiePopup;
