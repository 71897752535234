import styled from 'styled-components';

export const HeaderWrapper = styled.div`  
    width:100%;
    background-color: #37474f;
    border-color: #37474;
    height:50px;
    display:flex;
    flex-direction:row;
    align-items:center;
    img{
        height:16px;
        margin-left: 30px;
    }
    .logout-button {
        color:white;
        background-color:;
        font-weight:700;
        margin-left:70%;
    }
    .change-theme {
        color:white;
        background-color:;
        font-weight:300;
        margin-left:2%;
    }
`;

export const ShowNavBarIconForBrowser = styled.div`
    color:white;
    font-size:18px;
    display:inline-block;
    @media (max-width: 768px){
        display:none;
    }
`;

export const ShowNavBarIconForMobile = styled.div`
    display:none;
    color:white;
    @media (max-width: 768px){
        display:inline-block;
    }
`;
