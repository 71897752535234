import {createTheme} from '@mui/material/styles';

export const Themes = {
  NormalTheme: 0,
  DarkTheme: 1
};

export const getTheme = (theme) => {
  switch (theme) {
    case Themes.NormalTheme:
      return normalTheme;
    case Themes.DarkTheme:
      return darkTheme;
  }
  return null;
}


const theme = createTheme({
  
});

const darkTheme = createTheme({
  palette: {
    text_col: {
      main: '#f2aa4cff'
    },
    background_col: {
      main: '#101820ff'
    }
  },
  
});

const normalTheme = createTheme({
  palette: {
    text_col: {
      main: '#000000ff'
    },
    background_col: {
      main: '#ffffffff'
    }
  },
  typography: {
    fontSize: 19, // set the default font size for all Typography components
    h1: {
      fontSize: '2rem', // customize the font size for the h1 variant
    },
    subtitle1: {
      fontSize: '1.5rem', // customize the font size for the subtitle1 variant
    },
  },
  MuiBox: {
    styleOverrides: {
      root: {
        paddingTop: 15,
        paddingBottom: 15,
      },
    },
  },
})