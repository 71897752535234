import React, { useState, useEffect } from "react";
import NavBar from "../../componets/navBar/navBar.component";
import { DashboardWrapper } from "./dashboard.style";
import Header from "../../componets/header/header.component";
import { showNavBar } from "../../functions/functions";
import axios from "axios";
import { API_URL1, API_URL, API_URL_PICTURES } from "../../constants";
import LZString from "lz-string";
import * as Sentry from "@sentry/react";

let userSearchDB, userSearchDBstore, userSearchDBtx, userDbrequest;
let objects = [];

export const getQuestions = () => {
  return axios({
    withCredentials: true,
    method: "get",
    url: `${API_URL}/projects`,
  }).then((response) => {
    let objects = [];
    let size = Object.keys(response.data).length + 1;
    for (let i = 0; i < 100; ++i) {
      if (response.data[i] !== undefined) {
        objects.push(response.data[i]);
      }
      if (objects.length === size - 1) {
        break;
      }
    }

    localStorage.setItem("questionnaires", JSON.stringify(objects));
    let json = require("../../data.json");
    let jsonText = JSON.stringify(json);
    let jsonCompressed = LZString.compress(jsonText);
    localStorage.setItem("cities", jsonCompressed);

    // Set to track downloaded images
    const downloadedImages = new Set();

    for (let i = 0; i < objects.length; ++i) {
      axios({
        withCredentials: true,
        method: "get",
        url: `${API_URL}/project/` + objects[i].id,
      })
        .then((response) => {
          jsonText = JSON.stringify(response);
          
          jsonCompressed = LZString.compress(jsonText);
          localStorage.setItem("apiData" + objects[i].id, jsonCompressed);

          if (window.location.href.includes("localhost")) {
            localStorage.setItem("apiDataS" + objects[i].id, jsonText);
          }

          let userDbrequest = window.indexedDB.open("images", 3);
          userDbrequest.onupgradeneeded = function (event) {
            let userSearchDB = event.target.result;
            userSearchDB.createObjectStore("images");
          };

          userDbrequest.onerror = function (event) {
            console.log("ERROR: " + event.target.errorCode);
          };

          userDbrequest.onsuccess = function (event) {
            const userSearchDB = event.target.result;

            function fetchAndStoreImage(imageUrl) {
              if (downloadedImages.has(imageUrl)) {
                console.log(downloadedImages)
                return; // Skip if image is already downloaded
              }
              downloadedImages.add(imageUrl); // Add to the set of downloaded images

              axios({
                withCredentials: true,
                method: "get",
                url: imageUrl,
                responseType: "blob",
              })
                .then((response) => {
                  const blob = response.data;
                  const imageName = imageUrl.split("/").pop();
                  console.log(imageName)
                  if (imageName) {
                    const userSearchDBtx = userSearchDB.transaction(
                      "images",
                      "readwrite"
                    );
                    const userSearchDBstore =
                      userSearchDBtx.objectStore("images");
                    userSearchDBstore.put(blob, imageName);
                  }
                })
                .catch((error) =>
                  console.error("Fetch and store image error:", error)
                );
            }

            let dataApiString = localStorage.getItem("apiData" + objects[i].id);
            if (dataApiString) {
              let dataApi = JSON.parse(LZString.decompress(dataApiString));
              let newImages = [];
              

              Object.values(dataApi.data.sections).forEach((section) => {
                section.questions.forEach((question) => {
                  question.options.forEach((option) => {
                    if (question.type === "HM" && option.level === 1) {
                      let imageUrl = `${API_URL1}${option.text}`;
                      newImages.push(imageUrl);
                      fetchAndStoreImage(imageUrl);
                    }

                    if (question.hasImages === true || option.src !== "") {
                      let imageUrl = `${API_URL_PICTURES}${option.src}`;
                      newImages.push(imageUrl);
                      fetchAndStoreImage(imageUrl);
                    }
                  });
                });
              });
            }
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });
};

const Dashboard = ({ db }) => {
  const [openNavBar, setNavBar] = useState(false);
  const [images, setImages] = useState([]);

  function handleCloseNavBar() {
    setNavBar(false);
  }

  useEffect(() => {
    let dataApi = localStorage.getItem("apiData");
    dataApi = JSON.parse(dataApi);
  }, []);

  return (
    <DashboardWrapper className="DashboardWrapper">
      <Header NavBarSwitch={() => setNavBar(showNavBar(openNavBar))} />
      <NavBar
        showNavBarContent={openNavBar}
        closeNavBarContent={handleCloseNavBar}
      />
    </DashboardWrapper>
  );
};

export default Dashboard;
