import styled from 'styled-components';

export const LoginPageWrapper = styled.div`
  padding-top: 40px;
  height:100%;
  background-color: #37474F;
  border-color: #37474F;
`;

export const LoginContainer = styled.div`
  padding:20px 20px 60px 20px;
  display:flex;
  flex-direction:row;
  justify-content:center;
`;


export const LoginContent = styled.div`
  width:320px;
  max-width:320px;
  padding:20px;
  background-color:white;
  border-radius:3px;
  @media (max-width: 480px){
    width: 100%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-bottom: 20px;
  padding-top:10px;
  text-align:center;
`;

export const IconContainer = styled.div`
  border-color:#90A4AE;
  color:#90A4AE;
  border-radius:50%;
  margin-bottom:20px;
  padding:20px;
  border-style:solid;
  border-width:3px;
`;

export const HeaderTitle = styled.p`
  margin:0;
  font-family:inherit;
  font-weight:400;
  font-size:17px;
  line-height: 1.5384616;
`;

export const HeaderSubtitle = styled.p`
  margin:0;
  font-size:15px;
  font-weight: normal;
  line-height: 1;
  color: #999;
  padding-top:3px;
`;


export const FormContainer = styled.div`
  paddingTop:20px;
  display:flex;
  flex-direction:column;
  .ant-input {
    width:100%
  }
`;

