export function showNavBar (NavBar){
    if(NavBar === false) {
        return true
    } else {
       return false
    }
}

export function openModal(modal) {  
    if(modal === true){
        return true;
    } else {
        return false;
    }
}


export function changeQuestions(){

}

export function setTimer(id) {
    if(id != undefined){
        let timer = JSON.parse(localStorage.getItem('timers'));
        timer.push({
            id: id,
            timeStart: Date.now(),
            timeStop:"",
        })
        if(timer.length > 1){
            timer[timer.length-2].timeStop = Date.now();
        }
        localStorage.setItem('timers',JSON.stringify(timer))
    }
}