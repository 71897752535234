import styled from 'styled-components'
import { withStyles } from "@material-ui/styles";
import {Typography} from '@mui/material'

export const ProjectContainer = styled.div`
    background-color: #263238;
    color: rgba(255, 255, 255, 0.5);
    width:260px;
    height:100%;
    .sip-opener {
        outline:none;
    }
`;

export const GreenTextTypography = withStyles({
    root: {
      color: "green",
      display: "inline"
    }
})(Typography);

export const RedTextTypography = withStyles({
    root: {
      color: "red",
      display: "inline"
    }
})(Typography);