import React from 'react';
import { AppWrapper } from './App.style.js';
import LoginPage from './pages/login/login.page.js';
import Dashboard from './pages/dashboard/dashboard.page.js';
import Projects from './pages/projects/project.js';
import Form from './pages/form/form.js';
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const routes = [{ path: '/users' }, { path: '/projects' }, { path: '/form' }];

Sentry.init({
  dsn: "https://7943ab1f4f154e638e861d2a3653ac9e@o1128191.ingest.sentry.io/6308055",
  integrations: [new BrowserTracing()],

  // To set a uniform sample rate
  tracesSampleRate: 1.0,

  // Alternatively, to control sampling dynamically
  // tracesSampler: samplingContext => { ... }
});


function App() {
  return (  
      <Router basename={process.env.NODE_ENV === 'development' ? '/' : "/offline_quiz"} >
        <AppWrapper className='AppWrapper'> 
          <Switch>
            <Route exact path='/' component={LoginPage} />   
            <Route exact path={"/dashboard"} component={Dashboard}/>
            <Route exact path={"/projects"} component={Projects}/>
            <Route exact path={"/form"} component={Form}/>
            </Switch>
          </AppWrapper>
        </Router>
  );
}

export default App;
